<template>
  <ion-page>
    <Header name="Benutzerkonto" />
    <ion-content :fullscreen="true">
      <ion-alert
        :is-open="showDeleteAccountModal"
        header="Sicher?"
        message="Ihr Benutzerkonto wird unwiderruflich gelöscht. Sie können sich im Anschluss jederzeit neu registrieren."
        css-class="my-custom-class"
        :buttons="buttons"
        @didDismiss="setShowDeleteAccountModal(false)"
      >
      </ion-alert>
      <div id="container">
        <ion-grid>
          <ion-row>
            <ion-col v-if="showAccountDeleted">
              
              <div class="card">
                <h2>Benutzerkonto gelöscht</h2>
                <p>Ihr Benutzerkonto wurde erfolgreich gelöscht. Sie werden nun automatisch abgemeldet.</p>
              </div>

            </ion-col>
          </ion-row>
          <ion-row v-if="!showAccountDeleted">
            <ion-col>
              
              <div class="card">
                <h2>Benutzerkonto verwalten</h2>
                <a 
                  href="#" 
                  role="button"
                  class="logout btn btn-next btn-secondary"
                  @click.prevent="logout"
                >
                  <span>Abmelden</span>
                </a>
                <a 
                  href="#"
                  @click.prevent="showDeleteAccountModal = true"
                  class="delete-account"
                >
                  Benutzerkonto löschen
                </a>
              </div>

            </ion-col>
          </ion-row>
          <ion-row v-if="!showAccountDeleted">
            <ion-col>
              
              <div class="card">
                <h2>Datenschutz</h2>
                <a 
                  href="#" 
                  role="button"
                  class="logout btn btn-next btn-secondary"
                  @click.prevent="$cc.showSettings()"
                >
                  <span>Cookie-Einstellungen</span>
                </a>
              </div>

            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonAlert, IonPage, IonContent, IonCol, IonGrid, IonRow } from '@ionic/vue';
import Header from '@/components/Header.vue';

import { FCM } from '@capacitor-community/fcm';

// @ts-ignore
import * as api from '@/service/index.js'
import { AxiosError, AxiosResponse } from 'axios';

export default  {
  name: 'Profile',
  components: { 
    Header,
    IonAlert,
    IonContent, 
    IonPage, 
    IonCol, 
    IonGrid, 
    IonRow 
  },
  data () {
    return {
      showAccountDeleted: false,
      showDeleteAccountModal: false,
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            (this as any).setShowDeleteAccountModal(false)
          }
        },
        {
          text: 'Benutzerkonto löschen',
          cssClass: 'red',
          handler: () => {
            api.deleteAccount()
              .then((result: AxiosResponse) => {
                if (result.status === 200) {
                  (this as any).showAccountDeleted = true
                  setTimeout(() => {
                    localStorage.removeItem('jwt');
                    localStorage.removeItem('jwt_expires');
                    localStorage.removeItem('fcmSubscribed');
                    location.href = '/';
                  }, 5000)
                }
              })
              .catch((error: AxiosError) => {
                console.log(error);
              });
          }
        }
      ]
    }
  },
  methods: {
    setShowDeleteAccountModal (val: boolean) {
      (this as any).showDeleteAccountModal = val
    },
    logout () {
      console.log('logging out..');
      // const fcm = new FCM();
      FCM
        .unsubscribeFrom({ topic: 'private' })
        .then(() => {
          localStorage.removeItem('fcmSubscribed');
          console.log(`unsubscribed from topic "private"`);
        })
        .catch((err: any) => console.log(err));

      FCM
        .unsubscribeFrom({ topic: 'debug' })
        .then(() => {
          localStorage.removeItem('fcmSubscribed');
          console.log(`unsubscribed from topic "debug"`);
        })
        .catch((err: any) => console.log(err));

      FCM
        .unsubscribeFrom({ topic: 'debugSunzinet' })
        .then(() => {
          localStorage.removeItem('fcmSubscribed');
          console.log(`unsubscribed from topic "debugSunzinet"`);
        })
        .catch((err: any) => console.log(err));

      api.logout()
        .then((result: AxiosResponse) => {
          if (result.status === 200) {
            console.log('logged out..');
          }
          localStorage.removeItem('email');
          localStorage.removeItem('jwt');
          localStorage.removeItem('jwt_expires');
          localStorage.removeItem('fcmSubscribed');
          location.href = '/';
        })
        .catch((error: AxiosError) => {
          console.log(error);
          localStorage.removeItem('email');
          localStorage.removeItem('jwt');
          localStorage.removeItem('jwt_expires');
          localStorage.removeItem('fcmSubscribed');
          location.href = '/';
        });
    }
  }
}
</script>

<style scoped lang="less">
h2 {
  font-size: 28px;
  line-height: 37.7px;
  color: var(--text-color-blue);
  margin-top: 0;
  margin-bottom: 30px;
}
p {
  font-size: 15px;
  line-height: 28px;
  color: var(--text-color-grey);
}

@media (prefers-color-scheme: dark) {
  p {
    color: #fff;
  }
}
body.dark {
  p {
    color: #fff;
  }
}
</style>
<style lang="less">
.alert-button.red {
  color: red;
}
</style>