
import { IonAlert, IonPage, IonContent, IonCol, IonGrid, IonRow } from '@ionic/vue';
import Header from '@/components/Header.vue';

import { FCM } from '@capacitor-community/fcm';

// @ts-ignore
import * as api from '@/service/index.js'
import { AxiosError, AxiosResponse } from 'axios';

export default  {
  name: 'Profile',
  components: { 
    Header,
    IonAlert,
    IonContent, 
    IonPage, 
    IonCol, 
    IonGrid, 
    IonRow 
  },
  data () {
    return {
      showAccountDeleted: false,
      showDeleteAccountModal: false,
      buttons: [
        {
          text: 'Abbrechen',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            (this as any).setShowDeleteAccountModal(false)
          }
        },
        {
          text: 'Benutzerkonto löschen',
          cssClass: 'red',
          handler: () => {
            api.deleteAccount()
              .then((result: AxiosResponse) => {
                if (result.status === 200) {
                  (this as any).showAccountDeleted = true
                  setTimeout(() => {
                    localStorage.removeItem('jwt');
                    localStorage.removeItem('jwt_expires');
                    localStorage.removeItem('fcmSubscribed');
                    location.href = '/';
                  }, 5000)
                }
              })
              .catch((error: AxiosError) => {
                console.log(error);
              });
          }
        }
      ]
    }
  },
  methods: {
    setShowDeleteAccountModal (val: boolean) {
      (this as any).showDeleteAccountModal = val
    },
    logout () {
      console.log('logging out..');
      // const fcm = new FCM();
      FCM
        .unsubscribeFrom({ topic: 'private' })
        .then(() => {
          localStorage.removeItem('fcmSubscribed');
          console.log(`unsubscribed from topic "private"`);
        })
        .catch((err: any) => console.log(err));

      FCM
        .unsubscribeFrom({ topic: 'debug' })
        .then(() => {
          localStorage.removeItem('fcmSubscribed');
          console.log(`unsubscribed from topic "debug"`);
        })
        .catch((err: any) => console.log(err));

      FCM
        .unsubscribeFrom({ topic: 'debugSunzinet' })
        .then(() => {
          localStorage.removeItem('fcmSubscribed');
          console.log(`unsubscribed from topic "debugSunzinet"`);
        })
        .catch((err: any) => console.log(err));

      api.logout()
        .then((result: AxiosResponse) => {
          if (result.status === 200) {
            console.log('logged out..');
          }
          localStorage.removeItem('email');
          localStorage.removeItem('jwt');
          localStorage.removeItem('jwt_expires');
          localStorage.removeItem('fcmSubscribed');
          location.href = '/';
        })
        .catch((error: AxiosError) => {
          console.log(error);
          localStorage.removeItem('email');
          localStorage.removeItem('jwt');
          localStorage.removeItem('jwt_expires');
          localStorage.removeItem('fcmSubscribed');
          location.href = '/';
        });
    }
  }
}
